// import React, { useContext } from "react";
// import { SetPageTitle } from "../lib/";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { useEffect, useRef, useState } from "react";
import 'locomotive-scroll/dist/locomotive-scroll.css'

import Home from "./sections/Home";
import { AnimatePresence } from "framer-motion";
import About from "./sections/About";
import Shop from "./sections/Shop";
import ScrollTriggerProxy from './components/ScrollTriggerProxy';
import Banner from "./sections/Banner";
import NewArrival from "./sections/NewArrival";
import Footer from './sections/Footer';
import Loader from "./components/Loader";

import Pricing from './sections/Pricing';
import Testimonials from './sections/Testimonials';
import Timeline from './sections/Timeline';
import Faqs from './sections/Faqs';

export const LandingPage = () => {
    // const { config } = useContext(FireactContext);
    // const pathnames = config.pathnames;
    const { t } = useTranslation();

    // const signInUrl = pathnames.SignIn;
    const title = t("landingPageTitle");

    const containerRef = useRef(null);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
        setLoaded(true);
        }, 3000);
    }, [])

    return (
        <>
            {/* <SetPageTitle title={title} /> */}
            <GlobalStyles />
            
            <ThemeProvider theme={dark}>
            <LocomotiveScrollProvider
                options={{
                smooth: true,
                // ... all available Locomotive Scroll instance options
                smartphone:{
                    smooth:true,
                },
                tablet:{
                    smooth:true,
                }
                }}
                watch={
                [
                    //..all the dependencies you want to watch to update the scroll.
                    //  Basicaly, you would want to watch page/location changes
                    //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
                ]
                }
                containerRef={containerRef}
            >
            <AnimatePresence>
            {loaded ? null : <Loader />}
            </AnimatePresence>
            <ScrollTriggerProxy />
                <AnimatePresence>
                <main className='App' data-scroll-container ref={containerRef}>
                <Home />
                <Timeline />
                {/* <About /> */}
                {/* <Shop /> */}
                {/* <Banner /> */}
                {/* <NewArrival /> */}
                {/* <Testimonials /> */}
                {/* <Pricing /> */}
                <Faqs />
                <Footer />
                </main>
                </AnimatePresence>
            </LocomotiveScrollProvider>
            </ThemeProvider>
        </>
    );
}