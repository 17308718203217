import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Stack } from "@mui/material";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const Icon = ({ size, color }) => {
    return (
        <LocalFireDepartmentIcon color="warning" sx={{ fontSize: "151px" }} />
    );
};

export default function Item({ item }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card
            
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
                my:2
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography component="div" variant="h5">
                        {item.title}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                    >
                        {item.artistName}
                    </Typography>
                </CardContent>
                <Box
                    sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                >
                    <IconButton
                        aria-label="open audio file"
                        title="Open the audio file"
                        onClick={() => {
                            window.open(item.audioFileURL, "_blank");
                        }}
                    >
                        <MusicNoteIcon sx={{ height: 30, width: 30 }} />
                    </IconButton>
                    <IconButton
                        aria-label="open souce url"
                        title="Open the source URL"
                        onClick={() => {
                            window.open(item.sourceURL, "_blank");
                        }}
                    >
                        <OpenInNewIcon sx={{ height: 30, width: 30 }} />
                    </IconButton>
                    <IconButton
                        aria-label="view gate steps"
                        title="View gate steps"
                        onClick={handleClickOpen}
                    >
                        <VisibilityIcon sx={{ height: 30, width: 30 }} />
                    </IconButton>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                }}
            >
                <Stack>
                    <Typography variant="h4">20</Typography>
                    <Typography variant="subtitle1">Visitss</Typography>
                </Stack>
                <Stack>
                    <Typography variant="h4">20</Typography>
                    <Typography variant="subtitle1">Visitss</Typography>
                </Stack>
                <Stack>
                    <Typography variant="h4">20</Typography>
                    <Typography variant="subtitle1">Visitss</Typography>
                </Stack>
            </Box>
            <CardMedia
                component={Icon}
                sx={{ width: 151 }}
                alt="Live from space album cover"
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="alert-dialog-title">
                    Gate Steps Info
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack>
                            <div>
                                Collect fan emails:{" "}
                                <strong>
                                    {item.gateSteps?.email ? "Yes" : "No"}
                                </strong>
                            </div>
                            <div>
                                Collect fan first names:{" "}
                                <strong>
                                    {item.gateSteps?.firstName ? "Yes" : "No"}
                                </strong>
                            </div>
                            <div>
                                Follow on Instagram:{" "}
                                <strong>
                                    {item.gateSteps?.instagram?.follow
                                        ? "Yes"
                                        : "No"}
                                </strong>
                            </div>
                            <div>
                                Like on Instagram:{" "}
                                <strong>
                                    {item.gateSteps?.instagram?.like
                                        ? "Yes"
                                        : "No"}
                                </strong>
                            </div>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}
