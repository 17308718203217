import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import React from "react";
import styled from "styled-components";

const Section = styled.section`
  min-height: 25vh;
  width: 80vw;
  max-width: 720px;
  margin: 3em auto 0 auto;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 90vw;
  }
`;
const TimelineUL = styled.ul`
  border-left: 1px solid hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
    margin: 0;

  .timeline-item {
    position: relative;
  }
  
  .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
  }
  
  .timeline-icon {
    position: absolute;
    left: -48px;
    background-color: rgb(12, 23, 40);
    text-color: #FAFAFA;
    border-radius: 50%;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        color: #FAFAFA;
    }
    i::before {
        color: #FAFAFA;
    }
  }
`;


const Timeline = () => {
  return (
    <Section id="timeline">
    <MDBContainer fluid>
        <TimelineUL class="timeline-with-icons">
            <li class="timeline-item mb-5">
            <span class="timeline-icon">
                <i class="fas fa-music text-primary fa-sm fa-fw"></i>
            </span>

            <h5 class="fw-bold">Upload your songs</h5>
            <p class="text-muted mb-2 fw-bold">Step 1</p>
            <p class="text-muted">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            </li>

            <li class="timeline-item mb-5">

            <span class="timeline-icon">
                <i class="fas fa-rocket text-primary fa-sm fa-fw"></i>
            </span>
            <h5 class="fw-bold">Select your follow options</h5>
            <p class="text-muted mb-2 fw-bold">Step 2</p>
            <p class="text-muted">
                Quisque ornare dui nibh, sagittis egestas nisi luctus nec. Sed
                aliquet laoreet sapien, eget pulvinar lectus maximus vel.
                Phasellus suscipit porta mattis.
            </p>
            </li>

            <li class="timeline-item mb-5">

            <span class="timeline-icon">
                <i class="fas fa-share-nodes text-primary fa-sm fa-fw"></i>
            </span>
            <h5 class="fw-bold">Share your download link with your audience</h5>
            <p class="text-muted mb-2 fw-bold">Step 3</p>
            <p class="text-muted">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            </li>

            <li class="timeline-item mb-5">

            <span class="timeline-icon">
                <i class="fas fa-users text-primary fa-sm fa-fw"></i>
            </span>
            <h5 class="fw-bold">Earn fans!</h5>
            <p class="text-muted mb-2 fw-bold">Step 4</p>
            <p class="text-muted">
                Nulla ac tellus convallis, pulvinar nulla ac, fermentum diam. Sed
                et urna sit amet massa dapibus tristique non finibus ligula. Nam
                pharetra libero nibh, id feugiat tortor rhoncus vitae. Ut suscipit
                vulputate mattis.
            </p>
            </li>
        </TimelineUL>
    </MDBContainer>
    </Section>
  );
};

export default Timeline;
