import {
  AuthContext,
  FireactContext,
  SetPageTitle,
  SubscriptionContext,
} from "../";
import {
  Box,
  Button,
  Card,
  
  Container,
  
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import {
  collection,
  query,
  where,
  getDocs,
  
} from "firebase/firestore";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import Item from "./ItemList";

export const ListSubscriptions = ({ loader }) => {
  const { authInstance, firestoreInstance} =
    useContext(AuthContext);
  const { config } = useContext(FireactContext);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [setError] = useState(null);

  const { subscription } = useContext(SubscriptionContext);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const a = async () => {
      setLoaded(false);
      setError(null);

      try {
        const itemsColRef = collection(firestoreInstance, "items");
        const itemsQuery = query(
          itemsColRef,
          where("uid", "==", authInstance.currentUser.uid)
        );
        const itemsSnapshot = await getDocs(itemsQuery);
        const items = [];
        itemsSnapshot.forEach((doc) => {
          items.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setItems(items);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoaded(true);
      }
    };
    a();
  }, [firestoreInstance, authInstance, setError]);

  useEffect(() => {
    if (!subscription?.id) {
      navigate(config.pathnames.CreateSubscription);
    }
  }, [subscription, config.pathnames.CreateSubscription, navigate]);

  return (
    <>
      {loaded ? (
        <Container maxWidth="lx">
          <SetPageTitle title="My Subscription" />
          <Paper>
            <Stack p={2} direction="row" justifyContent="space-between">
              <Typography variant="h5">
                Current subscription: <b>{subscription.plan}</b>
              </Typography>
              {/* create button to redirect */}
              <Button
                onClick={() => {
                  navigate(config.pathnames.ChangeSubscription);
                }}
                variant="contained"
              >
                Upgrade Plan
              </Button>
            </Stack>
          </Paper>
          <Grid
            container
            spacing={2.5}
            sx={{
              textAlign: "center",
            }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                sx={{
                  my: 4,
                  p: 3,
                  border: "1px solid white",
                }}
              >
                <div>
                  <Typography fontWeight="bold" variant="h5">
                    Download Gates
                  </Typography>
                  <Typography variant="h4">2</Typography>
                  <Typography fontWeight="semibold" gutterBottom>
                    +2 in last 7 days
                  </Typography>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                sx={{
                  my: 4,
                  p: 3,
                  border: "1px solid white",
                }}
              >
                <div>
                  <Typography fontWeight="bold" variant="h5">
                    Fan visits
                  </Typography>
                  <Typography variant="h4">20</Typography>
                  <Typography fontWeight="semibold" gutterBottom>
                    +18 in last 7 days
                  </Typography>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                sx={{
                  my: 4,
                  p: 3,
                  border: "1px solid white",
                }}
              >
                <div>
                  <Typography fontWeight="bold" variant="h5">
                    New fans
                  </Typography>
                  <Typography variant="h4">16</Typography>
                  <Typography fontWeight="semibold" gutterBottom>
                    +15 in last 7 days
                  </Typography>
                </div>
              </Stack>
            </Grid>
          </Grid>
          {/* set height to 100px in the paper */}
          
            {items.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <IconButton
                  size="large"
                  color="primary"
                  onClick={() => {
                    navigate(config.pathnames.CreateItem);
                  }}
                  sx={{
                    fontSize: "10rem",
                  }}
                >
                  <AddCircleRoundedIcon fontSize="inherit" />
                </IconButton>
                <Typography
                  variant="subtitle1"
                  fontWeight="semibold"
                  fontSize="large"
                >
                  Add new item
                </Typography>
              </Box>
            ) : (
              <>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  p={2}
                  spacing={2}
                  >
                  <Typography variant="h5">Items</Typography>
                  <Button
                    onClick={() => {
                      navigate(config.pathnames.CreateItem);
                    }}
                    variant="contained"
                  >
                    Add Item
                  </Button>
                  </Stack>
                  {items.map((item,key) => (

                    <Item item={item} key={key} />
                  ))}
              </>
            )}
          
        </Container>
      ) : (
        <>{loader}</>
      )}
    </>
  );
};
