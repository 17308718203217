import React, { useContext } from "react";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { FireactContext } from "./Fireact";


export default function Plans({
    selectedPlanId,
    disabled,
    selectPlan,
    paymentMethod,
}) {
    const { config } = useContext(FireactContext);
    const plans = config.saas.plans;
    return (
        <>
            <Grid container spacing={5} alignItems="center" justifyContent="center">
                {plans.map((tier) => (
                    <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={tier.title === "LoopLife PRO" ? 12 : 6}
                        md={4.5}
                    >
                        <Card
                            sx={{
                                
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                                gap: 4,
                                border:
                                    selectedPlanId === tier.id
                                        ? 4
                                        : 0,
                                borderColor:
                                    selectedPlanId === tier.id
                                        ? "green"
                                        : undefined,
                                background:
                                    tier.title === "LoopLife PRO"
                                        ? "linear-gradient(#033363, #021F3B)"
                                        : undefined,
                            }}
                        >
                            <CardContent>
                                <Box
                                    sx={{
                                        mb: 1,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        color:
                                            tier.title === "LoopLife PRO"
                                                ? "grey.100"
                                                : "",
                                    }}
                                >
                                    <Typography component="h3" variant="h6">
                                        {tier.title}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        color:
                                            tier.title === "LoopLife PRO"
                                                ? "grey.50"
                                                : undefined,
                                    }}
                                >
                                    <Typography component="h3" variant="h3">
                                        €{tier.price}
                                    </Typography>
                                    <Typography component="h3" variant="h6">
                                        &nbsp; per month
                                    </Typography>
                                </Box>
                                <Divider
                                    sx={{
                                        my: 2,
                                        opacity: 0.2,
                                        borderColor: "grey.500",
                                    }}
                                />
                                {tier.description.map((line) => (
                                    <Box
                                        key={line}
                                        sx={{
                                            py: 1,
                                            display: "flex",
                                            gap: 1.5,
                                            alignItems: "center",
                                        }}
                                    >
                                        <CheckCircleRoundedIcon
                                            sx={{
                                                width: 20,
                                                color:
                                                    tier.title ===
                                                    "LoopLife PRO"
                                                        ? "primary.light"
                                                        : "primary.main",
                                            }}
                                        />
                                        <Typography
                                            component="text"
                                            variant="subtitle2"
                                            sx={{
                                                color:
                                                    tier.title ===
                                                    "LoopLife PRO"
                                                        ? "grey.200"
                                                        : undefined,
                                            }}
                                        >
                                            {line}
                                        </Typography>
                                    </Box>
                                ))}
                            </CardContent>
                            <CardActions>
                                <Button
                                    fullWidth
                                    disabled={
                                        disabled || selectedPlanId === tier.id
                                            ? true
                                            : false
                                    }
                                    variant={
                                        tier.popular ? "contained" : "outlined"
                                    }
                                    onClick={(e) => {
                                        selectPlan(tier);
                                    }}
                                    sx={{
                                        // change disabled button color
                                        '&.Mui-disabled': {
                                            // backgroundColor: tier.title === "LoopLife PRO" ? "grey.500" : "grey.200",
                                            color: tier.title === "LoopLife PRO" ? "grey.200" : "grey.800",
                                        },
                                    }}
                                >
                                    {tier.free || paymentMethod
                                        ? "Subscribe"
                                        : "Continue"}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
