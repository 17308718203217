import React from "react";
import styled from "styled-components";
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';

const Section = styled.section`
  min-height: 25vh;
  width: 80vw;
  margin: 4em auto 0 auto;

  position: relative;

  @media (max-width: 48em) {
    width: 90vw;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  @media (max-width: 64em) {
    justify-content: center;
  }

  button {
    color: ${(props) => props.theme.body} !important;
    border-color: ${(props) => props.theme.body} !important;
  }
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Kaushan Script";
  font-weight: 300;
  text-shadow: 1px 1px 1px ${(props) => props.theme.body};
  color: ${(props) => props.theme.body};
  margin-bottom: 1rem;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Faqs = () => {
  return (
    <Section>
        <Title data-scroll data-scroll-speed="-1">
            Faqs
        </Title>
      <Container id="up">
      <MDBAccordion flush>
        <MDBAccordionItem collapseId={1} headerTitle='Question Item #1'>
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
            moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
            Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
            shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
            proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
            aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle='Question Item #2'>
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
            moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
            Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
            shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
            proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
            aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={3} headerTitle='Question Item #3'>
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
            moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
            Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
            shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
            proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
            aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
        </MDBAccordionItem>
        </MDBAccordion>
      </Container>
    </Section>
  );
};

export default Faqs;
