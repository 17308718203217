import { Avatar, Divider, IconButton, Menu, MenuItem, InputLabel, Select } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import React, { useContext, useState } from "react";
import { AuthContext } from "../Auth";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { FireactContext } from "../Fireact";
import { useTranslation } from "react-i18next";

export const UserMenu = ({customItems}) => {
    const { t, i18n } = useTranslation();

    const { config } = useContext(FireactContext);
    const pathnames = config.pathnames;

    const profileUrl = pathnames.UserProfile;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeLanguage = (e) => {
        const newLang = e.target.value;
        i18n.changeLanguage(newLang);
    };
    
    const navigate = useNavigate();

    return (
        <AuthContext.Consumer>
            {(context) => (
                <>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
                        <InputLabel id="language-select-label">Language</InputLabel>
                        <Select 
                            labelId="language-select-label"
                            id="language-select"
                            value={i18n.language.split("-")[0]}
                            label="Language"
                            onChange={handleChangeLanguage}
                        >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="es">Español</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton ria-label="account of current user" aria-controls="menu-appbar" onClick={handleMenu} aria-haspopup="true">
                        <Avatar alt={context.authUser.user.displayName} src={context.authUser.user.photoURL?(context.authUser.user.photoURL):("https://ui-avatars.com/api/?name="+encodeURI(context.authUser.user.displayName)+"&background=007bff&size=64&color=f8f9fc")} />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        {profileUrl && 
                            [
                            <MenuItem key="profile-menu-item" onClick={(e) => {
                                e.preventDefault();
                                handleClose();
                                navigate(profileUrl);
                            }}>
                                <AccountBoxIcon sx={{marginRight: "10px"}} /> {t("profile")}
                            </MenuItem>,
                            <Divider key="profile-menu-divider" />
                            ]
                        }
                        {customItems}
                        <MenuItem onClick={(e)=>{
                            e.preventDefault();
                            handleClose();
                            const auth = getAuth();
                            signOut(auth).then(() => {
                                document.location.href = pathnames.ListSubscriptions;
                            });
                        }}>
                            <ExitToAppIcon sx={{marginRight: "10px"}} /> {t("signout")}
                        </MenuItem>
                    </Menu>
                </>
            )}
        </AuthContext.Consumer>
    )
}