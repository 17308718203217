import { AuthContext, FireactContext} from "../";
import { Alert, Box, Container, Paper, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { SubscriptionContext } from "./SubscriptionContext";
import { PaymentMethodForm } from "./PaymentMethodForm";
import { httpsCallable } from "firebase/functions";
import { doc, setDoc } from "firebase/firestore";
import { BillingDetails } from "./BillingDetails";
import Plans from "./Plans";
import { useNavigate } from "react-router-dom";


import Snackbar from "@mui/material/Snackbar";


export const ChangePlan = ({loader}) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const { subscription, setSubscription } = useContext(SubscriptionContext);
    const { config } = useContext(FireactContext);

    const { firestoreInstance, functionsInstance, authInstance } =
        useContext(AuthContext);

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [showPaymentMethod, setShowPaymentMethod] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [ setSuccess] = useState(false);

    const [billingDetails, setBillingDetails] = useState(null);
    const [paymentStep, setPaymentStep] = useState(1);

    const selectPlan = (plan) => {
        setProcessing(true);
        setError(null);
        if (plan.free || subscription.paymentMethod) {
            // subscribe to the new plan
            const changeSubscriptionPlan = httpsCallable(
                functionsInstance,
                "fireactjsSaas-changeSubscriptionPlan"
            );
            changeSubscriptionPlan({
                paymentMethodId: subscription.paymentMethod,
                billingDetails: null,
                planId: plan.id,
                subscriptionId: subscription.id,
            })
                .then(() => {
                    setSubscription((prevState) => ({
                        ...prevState,
                        plan: plan.title, // title of the plan
                        planId: plan.id, // price ID in stripe
                        paymentCycle: plan.frequency,
                        price: plan.price,
                        currency: plan.currency,
                    }));
                    setOpen(true);
                    setSuccess(true);
                    setProcessing(false);
                    navigate(config.pathnames.ListSubscriptions);
                })
                .catch((err) => {
                    setError(err.message);
                    setProcessing(false);
                });
        } else {
            // show payment method
            setSelectedPlan(plan);
            setShowPaymentMethod(true);
            setProcessing(false);
        }
    };

    const submitPlan = (paymentMethod) => {
        setProcessing(true);
        setError(null);
        const changeSubscriptionPlan = httpsCallable(
            functionsInstance,
            "fireactjsSaas-changeSubscriptionPlan"
        );
        changeSubscriptionPlan({
            paymentMethodId: paymentMethod.id,
            billingDetails: billingDetails,
            planId: selectedPlan.id,
            subscriptionId: subscription.id,
        })
            .then(() => {
                const pmRef = doc(
                    firestoreInstance,
                    "users/" +
                        authInstance.currentUser.uid +
                        "/paymentMethods/" +
                        paymentMethod.id
                );
                return setDoc(
                    pmRef,
                    {
                        type: paymentMethod.type,
                        cardBrand: paymentMethod.card.brand,
                        cardExpMonth: paymentMethod.card.exp_month,
                        cardExpYear: paymentMethod.card.exp_year,
                        cardLast4: paymentMethod.card.last4,
                    },
                    { merge: true }
                );
            })
            .then(() => {
                setSubscription((prevState) => ({
                    ...prevState,
                    plan: selectedPlan.title, // title of the plan
                    planId: selectedPlan.id, // price ID in stripe
                    paymentCycle: selectedPlan.frequency,
                    price: selectedPlan.price,
                    currency: selectedPlan.currency,
                    paymentMethod: paymentMethod.id,
                }));
                setOpen(true);
                setSuccess(true);
                setProcessing(false);
                navigate(config.pathnames.ListSubscriptions);
            })
            .catch((err) => {
                setError(err.message);
                setProcessing(false);
            });
    };

    return (
      <Container maxWidth="lg">
        {processing ? (
          loader
        ) : (
          <Paper>
            <Box p={5}>
              {showPaymentMethod ? (
                <Stack spacing={3}>
                  {paymentStep === 1 && (
                    <>
                      <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                      >
                        Your Billing Details
                      </Typography>
                      {error !== null && (
                        <Alert severity="error">{error}</Alert>
                      )}
                      <BillingDetails
                        buttonText={"Continue"}
                        setBillingDetailsObject={(obj) => {
                          setBillingDetails(obj);
                          setPaymentStep(2);
                        }}
                      />
                    </>
                  )}
                  {paymentStep === 2 && (
                    <>
                      <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                      >
                        Setup Payment Method
                      </Typography>
                      {error !== null && (
                        <Alert severity="error">{error}</Alert>
                      )}
                      <PaymentMethodForm
                        buttonText={"Subscribe"}
                        setPaymentMethod={submitPlan}
                        disabled={processing}
                      />
                    </>
                  )}
                </Stack>
              ) : (
                <Stack spacing={3}>
                  {error !== null && <Alert severity="error">{error}</Alert>}
                  <Typography
                    component="h2"
                    variant="h4"
                    color="text.primary"
                    alignItems="center"
                    align="center"
                    p={2}
                    mb={5}
                  >
                    Change Subscription
                  </Typography>
                  <div>
                    <Plans
                      selectedPlanId={subscription.planId}
                      selectPlan={selectPlan}
                      disabled={processing}
                      paymentMethod={subscription.paymentMethod}
                    />
                  </div>
                </Stack>
              )}
            </Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                Subscription plan changed successfully.
              </Alert>
            </Snackbar>
          </Paper>
        )}
      </Container>
    );
};
