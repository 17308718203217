import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
 
  getDocs,
  collection,
  query,
  where,
  limit,
} from "firebase/firestore";
import { AuthContext, FireactContext } from "../";
import { Alert, Box, Container } from "@mui/material";

export const SubscriptionContext = React.createContext({});

export const SubscriptionProvider = ({ loader }) => {
  const [subscription, setSubscription] = useState(null);
  const { firestoreInstance, authInstance } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const { config } = useContext(FireactContext);

  useEffect(() => {
    setError(null);

    //const subQuery = subscriptionsRef.where('permissions.'+defaultPermission, 'array-contains', firebaseApp.auth().currentUser.uid);
    const subQuery = query(
      collection(firestoreInstance, "/subscriptions"),
      where("ownerId", "==", authInstance.currentUser.uid),
      limit(1)
    );

    getDocs(subQuery)
      .then((docSnaps) => {
        if (docSnaps.size > 0) {
          setSubscription({
            id: docSnaps.docs[0].id,
            ...docSnaps.docs[0].data(),
          });
        } else {
          // setError("No subscription found");
          setSubscription({});
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [
    firestoreInstance,
    config.saas.subscription.singular,
    setError,
    authInstance.currentUser.uid,
  ]);

  return (
    <>
      {error !== null ? (
        <Box mt={10}>
          <Container maxWidth="sm">
            <Box component="span" m={5} textAlign="center">
              <Alert severity="error">{error}</Alert>
            </Box>
          </Container>
        </Box>
      ) : (
        <>
          {subscription !== null ? (
            <SubscriptionContext.Provider
              value={{ subscription, setSubscription }}
            >
              <Outlet />
            </SubscriptionContext.Provider>
          ) : (
            <Box mt={10}>
              <Container maxWidth="sm">
                <Box component="span" m={5} textAlign="center">
                  {loader}
                </Box>
              </Container>
            </Box>
          )}
        </>
      )}
    </>
  );
};
