import React, { useContext,  useState } from "react";
import { AuthContext, FireactContext } from "..";
import { SubscriptionContext } from "./SubscriptionContext";
import {
  TextField,
  Container,
  
  Alert,
  Box,
  Button,
  
  Typography,
  
  Stepper,
  Step,
  
  StepContent,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  StepButton,
  Snackbar,
  IconButton,
} from "@mui/material";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import AudioFileIcon from "@mui/icons-material/AudioFile";

import { useForm, Controller} from "react-hook-form";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { DropzoneArea } from "mui-file-dropzone";

import CloseIcon from "@mui/icons-material/Close";

import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { useSuccessMessageStore } from "../store";

const formSchema = z.object({
  title: z.string().min(3),
  artistName: z.string().min(3),
  gateEmail: z.boolean(),
  gateFirstName: z.boolean(),
  gateInstagramFollow: z.boolean(),
  gateInstagramLike: z.boolean(),
  sourceURL: z.string().url().optional().or(z.literal("")),
  audioFile: z
    .any()
    .refine((file) => file && file?.size > 0, "Audio File is required"),
});

const NUM_STEPS = 4;

export const CreateItem = () => {
  const { subscription } = useContext(SubscriptionContext);
  const { firestoreInstance, storageInstance } = useContext(AuthContext);
  const { config } = useContext(FireactContext);
  const auth = getAuth();
  const navigate = useNavigate();
  const pathnames = config.pathnames;

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const setSuccessMessage = useSuccessMessageStore((state) => state.setSuccessMessage);

  const totalSteps = () => {
    return NUM_STEPS;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          Array.from({ length: NUM_STEPS }, () => 0).findIndex(
            (step, i) => !(i in completed)
          )
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      sourceURL: "",
      title: "",
      artistName: "",
      gateEmail: false,
      gateFirstName: false,
      gateInstagramFollow: false,
      gateInstagramLike: false,
    },
  });

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    reset();
  };

  React.useEffect(() => {
    // if subscription is empty, redirect to subscription page
    if (!subscription?.id) {
      navigate(config.pathnames.ListSubscriptions);
    }
  }, [subscription, navigate, config.pathnames]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setError(null);
  };

  const onSubmit = async (data) => {
    console.log("Submitting data", data);

    setProcessing(true);
    setError(null);
    // upload file to storage
    // save it under user's uid
    const fileName = `${uuidv4()}-${data.audioFile.name}`;
    const storageRef = ref(
      storageInstance,
      `audioFiles/${auth.currentUser.uid}/${fileName}`
    );
    const file = data.audioFile;
    let downloadUrl;
    try {
      await uploadBytes(storageRef, file);
      downloadUrl = await getDownloadURL(storageRef);
      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file", error);
      setError("Error uploading file");
      setOpenSnackbar(true);
      setProcessing(false);
      return;
    }

    // save data to firestore
    try {
      const docRef = await addDoc(collection(firestoreInstance, "items"), {
        title: data.title,
        artistName: data.artistName,
        gateSteps: {
          email: data.gateEmail,
          firstName: data.gateFirstName,
          instagram: {
            follow: data.gateInstagramFollow,
            like: data.gateInstagramLike,
          },
        },
        sourceURL: data.sourceURL,
        audioFileURL: downloadUrl,
        uid: auth.currentUser.uid,
        createdAt: new Date(),
      });
      console.log("Document written with ID: ", docRef.id);
      handleReset();
      setSubmitSuccess(true);
      setSuccessMessage("Item created successfully");
      navigate(pathnames.ListSubscriptions);
    } catch (error) {
      console.error("Error saving data", error);
      setError("Error saving data");
      setOpenSnackbar(true);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Container maxWidth="lx">
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography variant="h4">Create Item</Typography>
        <form
          onSubmit={handleSubmit(onSubmit, (e) => {
            console.log("Error submitting form", e);
            setError("Please complete all steps");
            setOpenSnackbar(true);
          })}
        >
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step completed={completed[0]}>
              <StepButton
                optional={
                  <Typography variant="body1">
                    Enter source/track URL for your title
                  </Typography>
                }
                sx={{
                  "& .MuiStepLabel-label": { fontSize: "1.15rem", mt: 2 },
                }}
              >
                Source
              </StepButton>
              <StepContent>
                <Box>
                  <Controller
                    name="sourceURL"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          hiddenLabel
                          size="small"
                          id="sourceURL"
                          // type="url"
                          fullWidth
                          placeholder="https://www..."
                          error={!!errors.sourceURL}
                          helperText={errors.sourceURL?.message}
                          margin="normal"
                          required
                          {...field}
                        />
                      </>
                    )}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        const res = await trigger("sourceURL", {
                          shouldFocus: true,
                        });
                        if (res) {
                          handleComplete();
                        }
                      }}
                      sx={{ mt: 1, mr: 1 }}
                      type="button"
                    >
                      Next
                    </Button>
                    <Button
                      onClick={handleComplete}
                      sx={{ mt: 1, mr: 1 }}
                      variant="outlined"
                      type="button"
                    >
                      Enter Later
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step completed={completed[1]}>
              <StepButton
                optional={
                  <Typography variant="body1">
                    Upload audio file you want to share with fans (mp3, wav,
                    zip).
                  </Typography>
                }
                sx={{
                  "& .MuiStepLabel-label": { fontSize: "1.15rem", mt: 2 },
                }}
              >
                Upload
              </StepButton>
              <StepContent>
                <Controller
                  name="audioFile"
                  control={control}
                  render={({ field }) => (
                    <>
                      <DropzoneArea
                        acceptedFiles={[".mp3", ".wav", ".zip"]}
                        dropzoneText={"Choose a file or drop it here"}
                        onChange={(files) => {
                          console.log("Files:", files);
                          if (files.length > 0 && files[0].size > 0)
                            field.onChange(files[0]);
                        }}
                        clearOnUnmount
                        filesLimit={1}
                        showFileNames
                        useChipsForPreview
                        Icon={AudioFileIcon}
                      />
                      {errors.audioFile && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                          {errors.audioFile?.message}
                        </Alert>
                      )}
                    </>
                  )}
                />
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        const res = await trigger("audioFile", {
                          shouldFocus: true,
                        });
                        if (res) {
                          handleComplete();
                        }
                      }}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                    <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step completed={completed[2]}>
              <StepButton
                optional={
                  <Typography variant="body1">
                    Enter artist name and title.
                  </Typography>
                }
                sx={{
                  "& .MuiStepLabel-label": { fontSize: "1.15rem", mt: 2 },
                }}
              >
                Title & Artist
              </StepButton>
              <StepContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            hiddenLabel
                            size="small"
                            id="title"
                            // type="url"
                            fullWidth
                            placeholder="Classy..."
                            label="Enter Title"
                            error={!!errors.title}
                            helperText={errors.title?.message}
                            margin="normal"
                            {...field}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="artistName"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            hiddenLabel
                            size="small"
                            id="artistName"
                            // type="url"
                            fullWidth
                            placeholder="Sonic Boom"
                            label="Enter artist name"
                            error={!!errors.artistName}
                            helperText={errors.artistName?.message}
                            margin="normal"
                            {...field}
                          />
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        const res = await trigger(["artistName", "title"], {
                          shouldFocus: true,
                        });
                        if (res) {
                          handleComplete();
                        }
                      }}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                    <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step completed={completed[3]}>
              <StepButton
                optional={
                  <Typography variant="body1">
                    Choose how you want fans to support your content.
                  </Typography>
                }
                sx={{
                  "& .MuiStepLabel-label": { fontSize: "1.15rem", mt: 2 },
                }}
              >
                Gate Steps
              </StepButton>
              <StepContent>
                <Box>
                  <FormGroup>
                    <Controller
                      name="gateEmail"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            }
                            label="Collect fan emails"
                          />
                        </>
                      )}
                    />
                    <Controller
                      name="gateFirstName"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            }
                            label="Collect fan first names"
                          />
                        </>
                      )}
                    />
                    <Controller
                      name="gateInstagramFollow"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            }
                            label="Follow on Instagram"
                          />
                        </>
                      )}
                    />
                    <Controller
                      name="gateInstagramLike"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            }
                            label="Like on Instagram"
                          />
                        </>
                      )}
                    />
                  </FormGroup>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        const res = await trigger(["artistName", "title"], {
                          shouldFocus: true,
                        });
                        if (res) {
                          handleComplete();
                        }
                      }}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                    <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 4,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ mr: 1 }}
              disabled={processing}
            >
              Submit
            </Button>
            <Button type="button" onClick={handleReset}>
              Reset
            </Button>
          </Box>
        </form>
      </Box>
      <Snackbar
        open={openSnackbar}
        severity="error"
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={error}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        open={submitSuccess}
        severity="error"
        autoHideDuration={6000}
        onClose={() => {
          setSubmitSuccess(false);
        }}
        message={"Item created successfully"}
        action={
          <React.Fragment>
            <Button
              onClick={() => {
                navigate(config.pathnames.ListSubscriptions);
              }}
            >
              View
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setSubmitSuccess(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Container>
  );
};
