import React, { useEffect, useState, useContext } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
} from "firebase/auth";
import { getStorage , connectStorageEmulator } from "firebase/storage";
import { Navigate, Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";
import {
  doc,
  getFirestore,
  setDoc,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { FireactContext } from "./Fireact";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  // authorized user state
  const [authUser, setAuthUser] = useState({
    user: null,
    data: {},
    checked: false,
  });

  const { config } = useContext(FireactContext);
  const [firebaseApp, setFirebaseApp] = useState(null);
  const [authInstance, setAuthInstance] = useState(null);
  const [firestoreInstance, setFirestoreInstance] = useState(null);
  const [functionsInstance, setFunctionsInstance] = useState(null);
  const [storageInstance, setStorageInstance] = useState(null);

  useEffect(() => {
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const firestore = getFirestore(app);
    const functions = getFunctions(app);
    const storage = getStorage(app);

    if (process.env.NODE_ENV === "development") {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
      connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
      connectAuthEmulator(auth, "http://127.0.0.1:9099");
      connectStorageEmulator(storage, "127.0.0.1", 9199);
    }

    setFirebaseApp(app);
    setAuthInstance(auth);
    setFirestoreInstance(firestore);
    setFunctionsInstance(functions);
    setStorageInstance(storage);

    onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdToken().then((token) => {
          const userDoc = doc(firestore, "users", user.uid);
          setAuthUser((prevState) => ({
            ...prevState,
            user: user,
            checked: true,
          }));
          setDoc(
            userDoc,
            {
              displayName: user.displayName,
              photoURL: user.photoURL,
              email: user.email,
            },
            { merge: true }
          );
        });
      } else {
        setAuthUser((prevState) => ({
          ...prevState,
          user: null,
          checked: true,
        }));
      }
    });
  }, [config.firebaseConfig]);

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
        firebaseApp,
        authInstance,
        firestoreInstance,
        functionsInstance,
        storageInstance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthRoutes = ({ loader }) => {
  const { authUser } = useContext(AuthContext);
  const { config } = useContext(FireactContext);
  const signInPath = config.pathnames.SignIn;

  if (authUser.checked) {
    if (authUser.user !== null) {
      return <Outlet />;
    } else {
      return (
        <Navigate
          to={
            signInPath +
            "?re=" +
            document.location.pathname +
            document.location.search +
            document.location.hash
          }
        />
      );
    }
  } else {
    return (
      <Box mt={10}>
        <Container maxWidth="sm">
          <Box component="span" m={5} textAlign="center">
            {loader}
          </Box>
        </Container>
      </Box>
    );
  }
};
